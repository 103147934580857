import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadString,
  deleteObject,
  list,
} from "firebase/storage";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { storage } from "../firebase";
import { selectUser } from "../features/user/userSlice";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../components/LoadingSpinner";

const Profile = () => {
  const user = useSelector(selectUser);
  const [userMenus, setUserMenus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState(null);

  const buildHtmlContent = (jsonUrl) => {
    // Construir el contenido del archivo HTML utilizando el código proporcionado
    const html = `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <title>MENU</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta http-equiv="cache-control" content="no-cache" />
    <link href="https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.2.0/jquery.fancybox.min.css"
    />
    <style>
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: #000000;
        font-family: 'Urbanist', sans-serif;
      }

      body {
        overflow-x: hidden;
        margin-bottom: 50px;
        font-family: 'Urbanist', sans-serif;
      }
      .wellcome{
        margin-top: 30px;
        line-height: normal;
      }

      h4,
      h3 {
        font-weight: 600;
      }
      h5 {
        font-size: 20px;
        font-weight: 600;
      }

      #menu {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .menu-content {
        margin: 0 20px;
        min-width: 75%;
      }
     
      .menu-col-title h3 {
        margin-bottom: 0;
      }

      .menu-category {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 50px 0 30px 0;
      }

      h3 {
        font-size: 2rem;
      }

      .menu-subcategory {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        margin: 30px 0 0 20px;
      }

      .menu-subcategory-info p {
        margin-top: 0;
      }

      .menu-subcategory h5 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 22px;
      }

      .price-types {
        display: flex;
        justify-content: end;
        font-size: 15px;
        font-weight: 500;
        margin-top: 10px;
      }

      .menu-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1.5px dashed #000000;
        margin-top: 12px;
      }

      .menu-item p {
        font-size: 1rem;
      }

      .menu-item-content h4,
      p {
        margin-bottom: 0;
      }

      .primary-btn {
        color: inherit;
        text-decoration: none;
      }
      .primary-btn:hover {
        color: inherit;
        text-decoration: none;
      }

      .menu-item-price {
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 1rem;
        font-weight: 600;
        min-width: 20%;
      }

      .menu-item-price-two {
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 1rem;
        font-weight: 700;
        min-width: 25%;
      }
      .menu-item-price-three {
        display: flex;
        align-items: center;
        justify-content: end;
        font-size: 1rem;
        font-weight: 700;
        min-width: 25%;
      }

      .menu-item-price > div {
        margin-right: 5px;
      }
      .language {
        font-size: large;
      }

      @media (max-width: 767px) {
        .menu-col {
          width: 100%;
          
        }
        .menu-content {
         width: 100%;
         margin: 0 10px;
        }
        
        .menu-item-content {
          width: 60%;
        }
        .menu-item-content h4 {
          font-size: medium;
        }
        .menu-item-content p {
          font-size: small;
          width: 90%
        }
        .menu-item-price-three {
          font-size: 0.7rem;
          font-weight: 700;
          min-width: 20%;
        }
        .price-types {
        font-size: 0.7rem;
        font-weight: 800;
        margin: 20px 0 0px 0;
      }
      } 
    </style>
  </head>

  <body>
    <div class="wellcome" align="center"></div>
    <br>
    <div class="language" align="center" style="cursor: pointer;"></div>

    <div id="menu">
      <div class="menu-content">
        <div class="menu-col"></div>
      </div>
    </div>

    <script>
      const menuCol = document.querySelector(".menu-col");
      let lang = "es";

      const generateCategory = (category) => {
        const div = document.createElement("DIV");
        div.setAttribute("class", "menu-category");

        const h3 = document.createElement("H3");
        h3.setAttribute("class", "menu-category-title");

        const categoryName = category.info[lang].name;
        const categoryDescription = category.info[lang].description;

        h3.innerHTML = categoryName;
        div.appendChild(h3);

        if (categoryDescription) {
          const p = document.createElement("P");
          p.innerHTML = categoryDescription.replace(/\\n/g, "<br>");
          div.appendChild(p);
        }

        const priceTypes = category.priceTypes;

        if (priceTypes && Object.keys(priceTypes).length > 1) {
          const priceTypesText = Object.values(priceTypes)
            .filter((type) => type.id !== "undefined")
            .map((type) => type.name)
            .join(" | ");

          const priceTypesDiv = document.createElement("DIV");
          priceTypesDiv.setAttribute("class", "price-types");
          priceTypesDiv.innerHTML = priceTypesText;
          div.appendChild(priceTypesDiv);
        }

        return div;
      };

      const generateSubcategory = (subcategory) => {
        const containerDiv = document.createElement("DIV");

        const subcategoryDiv = document.createElement("DIV");
        subcategoryDiv.setAttribute("class", "menu-subcategory");

        const h5 = document.createElement("H5");
        const subcategoryName =
          subcategory.info && subcategory.info[lang]
            ? subcategory.info[lang].name
            : "";
        const texth5 = document.createTextNode(subcategoryName);
        h5.appendChild(texth5);

        subcategoryDiv.appendChild(h5);

        const subcategoryDescription =
          subcategory.info && subcategory.info[lang]
            ? subcategory.info[lang].description
            : "";

        if (subcategoryDescription) {
          const p = document.createElement("P");
          p.innerHTML = subcategoryDescription.replace(/\\n/g, "<br>");
          subcategoryDiv.appendChild(p);
        }

        containerDiv.appendChild(subcategoryDiv);

        const priceTypesDiv = document.createElement("DIV");
        priceTypesDiv.setAttribute("class", "price-types");

        if (
          subcategory.priceTypes &&
          Object.keys(subcategory.priceTypes).length > 1
        ) {
          const priceTypesText = Object.values(subcategory.priceTypes)
            .filter((type) => type.id !== "undefined")
            .map((type) => type.name)
            .join(" | ");

          priceTypesDiv.innerHTML = priceTypesText;
        }

        containerDiv.appendChild(priceTypesDiv);

        return containerDiv;
      };

      const generateProduct = (product) => {
        const productElement = document.createElement("DIV");
        productElement.setAttribute("class", "menu-item");

        const contentElement = document.createElement("DIV");
        contentElement.setAttribute("class", "menu-item-content");

        let infoElement;

        if (product.image) {
          infoElement = document.createElement("A");
          infoElement.setAttribute("data-fancybox", "");
          infoElement.setAttribute("class", "primary-btn");
          infoElement.setAttribute("href", product.image);
        } else {
          infoElement = document.createElement("DIV");
          infoElement.setAttribute("class", "primary-btn");
        }

        let h4 = document.createElement("H4");

        // Verificar si product.info[lang] existe antes de acceder a 'name'
        const productName =
          product.info && product.info[lang] ? product.info[lang].name : "";
        let textH4 = document.createTextNode(productName);

        h4.appendChild(textH4);
        infoElement.appendChild(h4);

        if (
          product.info &&
          product.info[lang] &&
          product.info[lang].description
        ) {
          let p = document.createElement("P");
          p.innerHTML = product.info[lang].description.replace(/\\n/g, "<br>");
          infoElement.appendChild(p);
        }
        contentElement.appendChild(infoElement);
        productElement.appendChild(contentElement);

        let priceElement = document.createElement("DIV");
        priceElement.setAttribute("class", "menu-item-price");

        let priceTypes = Object.keys(product.prices);

        if (priceTypes.length === 2) {
          priceElement.classList.add("menu-item-price-two");
        }
        if (priceTypes.length === 3) {
          priceElement.classList.add("menu-item-price-three");
        }

        for (let i = 0; i < priceTypes.length; i++) {
          let type = priceTypes[i];

          let typeElement = document.createElement("DIV");

          if (type !== "default") {
            typeElement.innerHTML = product.prices[type].value || "&nbsp;";
          } else {
            typeElement.innerHTML = ""; // Deja el espacio vacío
          }

          priceElement.appendChild(typeElement);

          // Agregar la línea "|" después de cada tipo, excepto el último
          if (type !== "default" && i < priceTypes.length - 1) {
            let separatorElement = document.createElement("DIV");
            separatorElement.innerHTML = " | ";
            priceElement.appendChild(separatorElement);
          }
        }

        productElement.appendChild(priceElement);

        return productElement;
      };
      const generateMenu = (data) => {
        menuCol.innerHTML = "";
        for (let i = 0; i < data.categories.length; i++) {
          const category = data.categories[i];

          const categoryElement = generateCategory(category);
          menuCol.appendChild(categoryElement);

          if (category.products) {
            for (let j = 0; j < category.products.length; j++) {
              const product = category.products[j];
              if (!product.disabled) {
                menuCol.appendChild(generateProduct(product));
              }
            }
          }

          if (category.categories) {
            for (let j = 0; j < category.categories.length; j++) {
              const subcategory = category.categories[j];

              // Mostrar la subcategoría solo si tiene productos no deshabilitados
              if (
                subcategory.products &&
                subcategory.products.some((product) => !product.disabled)
              ) {
                const subcategoryElement = generateSubcategory(subcategory);
                menuCol.appendChild(subcategoryElement);

                for (let k = 0; k < subcategory.products.length; k++) {
                  const product = subcategory.products[k];
                  if (!product.disabled) {
                    menuCol.appendChild(generateProduct(product));
                  }
                }
              }
            }
          }
        }
      };
      fetch(
        "${jsonUrl}"
      )
        .then((res) => res.json())
        .then(generateMenu);
      const lengButton = document.querySelector(".language");
      const wellcome = document.querySelector(".wellcome");
      wellcome.innerHTML =
            "Presionando sobre cada plato, postre o trago podrás visualizar una imagen del mismo.";
      lengButton.innerHTML = "English Version";

      lengButton.addEventListener("click", (e) => {
        menuCol.innerHTML = "";
        lang = lang == "es" ? "en" : "es";

        if (lang == "es") {
          lengButton.innerHTML = "English Version";

          wellcome.innerHTML =
            "Presionando sobre cada plato, postre o trago podrás visualizar una imagen del mismo.";
        } else if (lang == "en") {
          lengButton.innerHTML = "Version En Español";
          wellcome.innerHTML =
            "Pressing on each plate, you will be able to visualize an image of it.";
        }

        fetch(
          "${jsonUrl}"
        )
          .then((res) => res.json())
          .then(generateMenu);
      });
    </script>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.2.0/jquery.fancybox.min.js"></script>
  </body>
</html>
    
`;
    return html;
  };

  useEffect(() => {
    let isMounted = true; // Bandera para verificar si el componente está montado
    
    const fetchUserMenus = async () => {
      try {
        setIsLoading(true);
        const storageRef = ref(storage, `menu/${user.uid}`);
  
        const menuList = [];
  
        const menuFiles = await listAll(storageRef);
  
        for (const file of menuFiles.items) {
          if (file.name.endsWith(".json")) {
            const downloadURL = await getDownloadURL(file);
            const response = await fetch(downloadURL);
            const menuData = await response.json();
  
            const viewName = menuData.title.toLowerCase().replace(/\s/g, "-");
            const viewRef = ref(storage, `menu/${user.uid}/${viewName}.html`);
  
            try {
              const viewUrl = await getDownloadURL(viewRef);
              menuData.menuUrl = viewUrl;
  
              // get the url of the html file
              const htmlRef = ref(storage, `menu/${user.uid}/${viewName}.html`);
              const htmlUrl = await getDownloadURL(htmlRef);
              menuData.htmlUrl = htmlUrl;
            } catch (error) {
              console.log("ERROR AL OBTENER LA URL DEL MENÚ:", error);
            }
            const menu = {
              menuData,
              name: file.name.replace(".json", ""),
            };
  
            menuList.push(menu);
          }
        }
  
        if (isMounted) {
          setUserMenus(menuList);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error al cargar los menús:", error);
        setIsLoading(false);
      }
    };
  
    fetchUserMenus();
  
    return () => {
      isMounted = false; // Marcar el componente como desmontado al limpiar el useEffect
    };
  }, [user.uid]);
  
  

  const containerStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    textAlign: "center",
    padding: "0 20px",
    minHeight: "100vh",
    color: "black",
  };

  const cardStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    color: "black",
    border: "none",
    boxShadow: "5px 5px 10px 1px  rgba(10, 10,10, 0.3)",
  };

  const CreateNewMenu = async () => {
    // Pedir el título para el nuevo menú
    const newMenuTitle = prompt("Introduce el título del menú:");
    if (!newMenuTitle) {
      return;
    }

    // Convertir el título a minúsculas y eliminar espacios en blanco
    const menuHtmlTitle = newMenuTitle.toLowerCase().replace(/\s/g, "-");

    // Verificar si ya existe un menú con ese nombre
    const menuExists = await checkMenuExists(menuHtmlTitle);
    if (menuExists) {
      alert("Ya existe un menú con ese nombre. Elige un nombre diferente.");
      return;
    }

    const newMenu = {
      title: newMenuTitle,
      categories: [],
    };

    const newMenuJson = JSON.stringify(newMenu);

    // Referencia para el archivo JSON
    const newMenuJsonRef = ref(
      storage,
      `menu/${user.uid}/${menuHtmlTitle}.json`
    );

    // Referencia para el archivo HTML
    const newMenuViewRef = ref(
      storage,
      `menu/${user.uid}/${menuHtmlTitle}.html`
    );

    // Subir el archivo JSON
    await uploadString(newMenuJsonRef, newMenuJson)
      .then(() => {
        console.log("Menú creado exitosamente.");
      })
      .catch((error) => {
        console.error("Error al crear el menú JSON:", error);
      });

    // Obtener la URL del archivo JSON
    const downloadUrl = await getDownloadURL(newMenuJsonRef);

    // Construir el contenido HTML
    let menuHtml = buildHtmlContent(downloadUrl);

    // Subir el archivo HTML
    await uploadString(newMenuViewRef, menuHtml, "raw", {
      contentType: "text/html",
    })
      .then(() => {
        console.log("Menú creado exitosamente.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error al crear el menú HTML:", error);
      });
  };

  const checkMenuExists = async (menuHtmlTitle) => {
    try {
      // Referencia al directorio donde se almacenan los menús
      const menuDirectoryRef = ref(storage, `menu/${user.uid}/`);

      // Obtener la lista de elementos en el directorio
      const menuItems = await list(menuDirectoryRef);

      // Verificar si el menú existe en la lista
      return menuItems.items.some(
        (item) => item.name === `${menuHtmlTitle}.json`
      );
    } catch (error) {
      console.error("Error al verificar la existencia del menú:", error);
      return false; // Tratar como si el menú no existe en caso de error
    }
  };

  const generateQRCode = (menuUrl) => {
    return (
      <QRCodeCanvas
        value={menuUrl}
        size={150}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };

  const deleteMenu = (menuName) => {
    setMenuToDelete(menuName);
    setDeleteModalVisible(true);
  };
  const confirmDeleteMenu = async () => {
    const menuName = menuToDelete.toLowerCase();
    try {
      const menuRef = ref(storage, `menu/${user.uid}/${menuName}.json`);
      const htmlRef = ref(storage, `menu/${user.uid}/${menuName}.html`);

      await Promise.all([deleteObject(menuRef), deleteObject(htmlRef)]);

      console.log("Menu deleted successfully.");
      setDeleteModalVisible(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting menu:", error);
    }
  };
  const cancelDeleteMenu = () => {
    setMenuToDelete(null);
    setDeleteModalVisible(false);
  };

  return (
    <div style={containerStyle}>
      <Container>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {userMenus.length > 0 ? (
              <>
                <h2 className="mb-4">Tus Menús</h2>
                <div>
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {userMenus.map((menu, index) => (
                      <div key={index} className="col">
                        <Card style={cardStyle}>
                          <Card.Img variant="top" src={menu.menuData.imageUrl} />
                          <Card.Body>
                            <Card.Title>{menu.menuData.title}</Card.Title>
                            <Link
                              target="blank"
                              to={`${menu.menuData.menuUrl}`}
                              className="btn"
                            >
                              Ver Menú
                            </Link>
                            <Link
                              to={`./${menu.menuData.title}`}
                              className="btn btn-dark"
                            >
                              Editar Menú
                            </Link>
                            <div className="menu-qr">
                              {generateQRCode(menu.menuData.menuUrl)}
                            </div>
                            <button
                              onClick={() => deleteMenu(menu.name)}
                              className="btn btn-danger"
                            >
                              Eliminar Menú
                            </button>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-4 btn btn-primary" onClick={CreateNewMenu}>
                  Crear Nuevo Menú
                </div>
              </>
            ) : (
              <>
                <div>
                  <p>No tienes menús disponibles.</p>
                </div>
                <div className="mt-4 btn btn-primary" onClick={CreateNewMenu}>
                  Crear Nuevo Menú
                </div>
              </>
            )}
          </>
        )}
        <CustomModal
          title="Confirmar Eliminación"
          show={isDeleteModalVisible}
          onConfirm={confirmDeleteMenu}
          onClose={cancelDeleteMenu}
        >
          ¿Estás seguro de que quieres eliminar este menú? Perderás toda la
          información.
        </CustomModal>
      </Container>
    </div>
  );
  
  
  
  
  
};

export default Profile;

import React from "react";

import InfoEditor from "./InfoEditor";

const InfosEditor = ({ langs, info, onChange,productId,isCollapsed}) => {
  
  const onChangeInfo = (lang, update) => {
    onChange({
      ...info,
      [lang]: update,
    });
  };

  return (
    <div className={`infos ${isCollapsed ? "collapsed" : ""}`}>
      {langs.map((lang) => (
        <div key={`infos_${lang}`}>
          <InfoEditor
            productId={productId}
            info={info?.[lang]}
            onChange={(update) => onChangeInfo(lang, update)}
            lang={lang}
            isCollapsed={isCollapsed}
          />
        </div>
      ))}
    </div>
  );
};

export default InfosEditor;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", action.payload);
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
    initializeUser: (state) => {
      //console.log("Iniciando initializeUser()");
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        state.user = storedUser;
      }
      //console.log("Usuario inicializado:", localStorage.getItem("user"));
    },
  },
});

export const { setUser, clearUser, initializeUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;

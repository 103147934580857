import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "./screens/Home";
import Profile from "./screens/Profile";
import { CreateMenu } from "./screens/CreateMenu";
import { useSelector } from "react-redux";
import MenuEditor from "./screens/MenuEditor";
import { selectUser, initializeUser } from "./features/user/userSlice";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { store } from "./app/store";


function App() {
  const user = useSelector(selectUser);
  
   // Llama a initializeUser() cuando el componente se monta
   useEffect(() => {
    store.dispatch(initializeUser());
  }, []); // [] asegura que se ejecute solo una vez al montar el componente

  return (
    <div className="App">
      <BrowserRouter>
        <ResponsiveAppBar />
        <Routes>
          {user ? (
            <>
              <Route path="/" element={<Navigate to={`/profile/${user.uid}`} />} />
              <Route element={<PrivateRouteWrapper />}>
                <Route
                  path="/profile/:uid/:menuId"
                  element={<MenuEditor />}
                />
                <Route path="/profile/:uid" element={<Profile />} />
                <Route path="/nuevo-menu" element={<CreateMenu />} />
              </Route>
            </>
          ) : (
            <Route path="/" element={<Home />} />
          )}
          {!user && <Route path="*" element={<Navigate to="/" replace />} />}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function PrivateRouteWrapper() {
  return <Outlet />;
}

export default App;
import React, { useState } from "react";
import "../css/home.css";
import ContactModal from "../components/ContactModal";
import { FaLanguage, FaInfinity } from "react-icons/fa6";
import { MdOutlinePriceCheck } from "react-icons/md";
import { PiImagesFill } from "react-icons/pi";
import { FaEdit, FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { PiPencilSimpleLineDuotone } from "react-icons/pi";
import ClientCarousel from "./ClientCarousel";

const Home = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <div className="epic-intro-container">
      <div className="yellow"></div>
      <div className="back">
        <div className="grey">
          <div className="content-top">
            <div className="content-top-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2Ftop-image.jpg?alt=media&token=39f80c0c-10fb-4630-8adf-b5ce1646ee7d"
                alt="example restauradmin mobile image"
                loading="lazy"
              />
            </div>
            <div className="content-top-text">
              <h1>¡Explorá RestaurAdmin y potenciá tu restaurante!</h1>
              <p>
                Descubrí la forma más sencilla de gestionar tus menús.
                Personalizá cartas, organizá platos y establecé precios
                diferenciados en varios idiomas con RestaurAdmin. ¡Explorá una
                experiencia gastronómica única al alcance de un clic!
              </p>
              <button className="form-button" onClick={handleShow}>
                ¡Comenzá tu Experiencia Gastronómica!
              </button>
            </div>
          </div>
        </div>
      </div>
      <h2 className="info-section-title">¿QÚE OFRECEMOS?</h2>
      <div className="info-section">
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <PiImagesFill className="icon" />
          </div>
          <h3>Imágenes Personalizadas</h3>
          <p>Subí imagenes para desptacar visualmente tu oferta.</p>
        </div>
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <MdOutlinePriceCheck className="icon" />
          </div>
          <h3>Precios Personalizados</h3>
          <p>Asigná precios individuales o dobles para platos especiales.</p>
        </div>
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <FaLanguage className="icon" />
          </div>

          <h3>Menú en Varios Idiomas</h3>
          <p>Tu menú en dos idiomas para llegar a todavía más gente.</p>
        </div>
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <FaEdit className="icon" />
          </div>
          <h3>Auto gestión</h3>
          <p>Mantené tu carta actualizada desde cualquier lugar.</p>
        </div>
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <FaInfinity className="icon" />
          </div>
          <h3>Menús y Productos Ilimitados</h3>
          <p>¿Tenés más de un local? Todas tus cartas en un mismo lugar.</p>
        </div>
        <div className="info-section-element">
          <div className="rounded-circle info-icon custom-image">
            <PiPencilSimpleLineDuotone className="icon" />
          </div>

          <h3>Diseño Personalizado</h3>
          <p>Mantenemos la identidad de tu restaurante</p>
        </div>
      </div>

      <ContactModal showModal={showModal} handleClose={handleClose} />
      <br />
      <br />
      <ClientCarousel/>

      <footer className="footer">
        <div className="footer-mid">
          <h3>¿Querés saber más sobre nosotros?</h3>
          <p>Ponete en contacto y despejá todas tus dudas.</p>
          <button className="form-button" onClick={handleShow}>
            ¡Comenzá tu Experiencia Gastronómica!
          </button>
        </div>
        <div className="footer-info">
          <p>cartadigitalweb@gmail.com</p>
        </div>

        <div className="redes">
          <a href="https://www.instagram.com/restauradmin/"><FaInstagram className="instagram" /></a>
          
          <a href="https://www.facebook.com/profile.php?id=61554132142237&sk=followers"> <FaFacebookSquare className="facebook" /></a>
         
        </div>
      </footer>
    </div>
  );
};

export default Home;

import { Button, Modal } from "react-bootstrap";
import { BsPlay, BsPause } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { useState } from "react";

const EditorActions = ({ product,  onProductUpdated, deleteProduct }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const onToggleStatus = (event) => {
    onProductUpdated({ ...product, disabled: !product.disabled });
  };

  const _onDeleteProduct = (event) => {
    setShowDeleteConfirmation(true);
  };
  const confirmDelete = () => {
    deleteProduct(product);
    setShowDeleteConfirmation(false);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    
  };

  return (
    <div className="editorActionsStyles">
      {product.disabled ? (
        <Button
          variant="outline-success"
          onClick={onToggleStatus}
          className="pause-button"
        >
          <BsPlay className="pause-icon" />
          Activar
        </Button>
      ) : (
        <Button
          variant="outline-danger"
          onClick={onToggleStatus}
          className="pause-button"
        >
          <BsPause className="pause-icon" />
          Pausar
        </Button>
      )}

      <Button onClick={_onDeleteProduct} className="trash-icon" variant="outline-danger">
        <BiTrash  />
        Eliminar
      </Button>

      <Modal show={showDeleteConfirmation} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar este producto?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditorActions;

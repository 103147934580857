import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CategoryEditor from "./CategoryEditor";
import { useMenuContext } from "../../contexts/ContextProvider";

const CategoriesEditor = ({ parent, level, updateCategoriesOrder }) => {
  const [localCategories, setLocalCategories] = useState(parent.categories);
  const { updateSubcategoriesOrder } = useMenuContext();

  useEffect(() => {
    // Actualiza el estado local cuando cambian las propiedades (categorías)
    setLocalCategories(parent.categories);
  }, [parent.categories]);

  const onDragEnd = (result) => {
    // Verifica si la operación de arrastrar y soltar fue completada
    if (!result.destination) {
      return;
    }

    // Update position of categories
    const newCategories = Array.from(localCategories);
    const [removed] = newCategories.splice(result.source.index, 1);
    newCategories.splice(result.destination.index, 0, removed);

    // if level = 0 update subcategories order
    if (level === 0) {
      updateSubcategoriesOrder(parent, newCategories);
    }

    // Actualiza el estado local solo si hay cambios en el orden
    if (!arraysEqual(localCategories, newCategories)) {
      setLocalCategories(newCategories);
      if (level === 1) {
        updateSubcategoriesOrder(parent, newCategories);
        return;
      }
      updateCategoriesOrder(parent, newCategories);
    }
  };

  const arraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`categories-${parent.id}`} type="CATEGORY">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {localCategories.map((category, index) => (
              <Draggable
                key={category.id}
                draggableId={category.id}
                index={index}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <CategoryEditor
                      level={level}
                      parent={parent}
                      category={category}
                      providedDragHandle={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CategoriesEditor;

import React from "react";
import Slider from "react-slick";
import "../css/home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600, // Ajustar para pantallas más pequeñas
        settings: {
          slidesToShow: 4, // Cambiar la cantidad de elementos mostrados en pantallas pequeñas
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <br />
      <h2>NUESTROS CLIENTES</h2>
      <p>Presiona sobre cada logo para ver su Menú Digital!</p>
      <br />
      <Slider className="slick-slider" {...settings}>
        <div className="card-info">
          <a href="http://www.oterobar.com/menuqr.html">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2Flogo-otero.jpg?alt=media&token=182de2e9-c563-4789-b850-ab5a7b347f28"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>OTERO BAR</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="http://www.ladespensadeaurora.com/cartadespensa.html">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2Fdespensa-logo.jpg?alt=media&token=7bc415f7-6a95-48"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>La Despensa de Aurora</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/simona">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2Fsimona-logo.JPG?alt=media&token=08a8fa09-11ab-4848-a0fb-ddb9b1ab0fd2"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Simona Bakery Shop</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/glorias">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2Flogo-glorias%20.png?alt=media&token=d99bd524-35db-4915-9304-0b38e446edb4"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>GLORIAS</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/villa">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2FLogo-villa.png?alt=media&token=ad2631bb-3c42-440d-8cc7-ad6c637fc547"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Villa farica de Pan</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/aguila">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2Faguila-logo-simple.png?alt=media&token=78b40226-b79e-435f-8513-e08d81e74b00"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Aguila</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/despacho">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2Fdespacho-logo.PNG?alt=media&token=566819a8-1dc3-4e6e-92f6-a2e2d055b79e"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Despacho & Afines</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/ruiz-rotiseria">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2Flogoruiz.png?alt=media&token=7f800723-1fe0-4ed1-9dbe-bc76a7117960"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Ruiz Rotisería</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/buffet-campeon">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2FBuffet-campeon-logo.png?alt=media&token=a779b173-dde1-4f7b-8388-7ead7e44fcf3"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Buffet Campeon</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/pesceria">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2FwqKXFyMil6R2RQ0c5M34TsTUhXG3%2Fmenu-images%2Flogopesceria.png?alt=media&token=f9a57a3e-6146-4d8d-895e-2232fbce65da"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>La Pesceria</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/abuela22">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2FimRyKhSdoseH8NVeWkKkXBFUMFN2%2Fmenu-images%2Flogoabuela-03.png?alt=media&token=c71b783f-9163-43da-84a2-9f26d86cbbba"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Abuela 22</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/hotel-boutique-vinos">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2F72AcQXCGtQO9TqmHtpCltQkLMMi1%2Fmenu-images%2Flamarialogo.png?alt=media&token=b8686688-47cb-4733-91dd-f01abb870e37"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Hotel La Maria Vinos</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/work-cafe-barrio-norte">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2Fyx4KxecjRRRP5i2SNEKQQTNyfmB2%2Fmenu-images%2Fworklogotrans.png?alt=media&token=b63fe9bb-8cf1-4b26-81a1-1c6a9c7d8eae"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Work Cafe</h3>
          </a>
        </div>
        <div className="card-info">
          <a href="https://restauradmin.com.ar/centrorestaurante">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/menu%2FaBybEdcwe4Y2XBow75xGzJz4RTB3%2Fmenu-images%2FLOGO%20AZUL.png?alt=media&token=8b3267e3-eedb-4f42-99e2-fc3318a5fcab"
              alt="Multilingüe"
              className="img-fluid rounded-circle custom-image"
              loading="lazy"
            />
            <h3>Centro Restaurante</h3>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default ClientCarousel;

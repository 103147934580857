import React from "react";
import PriceTypeEditor from "./PriceTypeEditor";
import { Button, Form } from "react-bootstrap";
import { BiEditAlt, BiPlus } from "react-icons/bi";
import CustomModal from "../CustomModal";

const PriceTypesEditor = ({ priceTypes, onChange, onAdd, onDelete }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleClose = () => {setShowConfirmation(false)};
  const handleShow = () => setShowConfirmation(true);

  

  const renderPriceTypes = () => {
    return (
      <div className="price-types">
        <div>
          <Form.Text className="text-muted ">
            Ingresa un nombre descriptivo para cada tipo de precio (Ej. Simple,
            Doble, Triple).
          </Form.Text>
          {Object.entries(priceTypes).map(([key, priceType]) => (
            <PriceTypeEditor
              key={key}
              priceType={priceType}
              priceTypes={priceTypes}
              onChange={(p) =>
                onChange({
                  ...priceTypes,
                  [key]: p,
                })
              }
              onDelete={(p) =>
                onDelete({
                  ...priceType,
                  id: key,
                })
              }
            />
          ))}
        </div>
        <Button onClick={onAdd} variant="" className="add-button">
          <BiPlus />
          Add
        </Button>
      </div>
    );
  };

  return (
    <div className="add-price-type">
      <Button onClick={handleShow} variant="">
        <BiEditAlt />
        <p>Tipos de precios</p>
      </Button>
      <CustomModal
        show={showConfirmation}
        title="Agrega más de un precio"
        children={renderPriceTypes()}
        size="lg"
        onClose={handleClose}
      ></CustomModal>
    </div>
  );
};

export default PriceTypesEditor;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs";
import {
  signOutUser,
  initAuthStateListener,
  signInWithEmailPassword,
} from "../services/authService";
import { selectUser } from "../features/user/userSlice";

const ResponsiveAppBar = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignOut = async () => {
    try {
      await signOutUser(dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignIn = () => {
    signInWithEmailPassword(email, password, dispatch);
    setShowModal(false);
  };

  useEffect(() => {
    const unsubscribe = initAuthStateListener(dispatch);
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Navbar expand="xl" variant="dark" className="mb-0 nav-bar ">
      <Container fluid>
        <Navbar.Brand as={Link} to={"/"} className="text-white fs-3">
          
            <img src={"https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2FLOGOS-06.png?alt=media&token=d9806355-cf2b-49aa-87dc-f84a0deefa6c"} alt="Logo" className="navbar-logo" />
         
        </Navbar.Brand>
        
        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          style={{ border: "none" }}
        >
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="offcanvasNavbar">
          <Nav className="ms-auto">
            {user && (
              <>
                <Nav.Link
                  as={Link}
                  to={`/profile/${user.uid}`}
                  className="text-white"
                >
                  Perfil
                </Nav.Link>
                <Button variant="outline-light" onClick={handleSignOut}>
                  <BsBoxArrowInLeft /> Cerrar Sesión
                </Button>
              </>
            )}
            {!user && (
              <>
                <Button
                  variant="outline-light"
                  onClick={() => setShowModal(true)}
                >
                  <BsBoxArrowInRight /> Iniciar Sesión
                </Button>
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Iniciar Sesión</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSignIn}>
                      Iniciar Sesión
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ResponsiveAppBar;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAbRAOe2kewcrRYLyDPgyoUfRckIWNAzF8",
  authDomain: "restauradmin.firebaseapp.com",
  projectId: "restauradmin",
  storageBucket: "restauradmin.appspot.com",
  messagingSenderId: "197138197382",
  appId: "1:197138197382:web:f3746bfbf1201451b25a07",
  measurementId: "G-69G1V4WFYQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  firestore,
  db,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
};

import React, { useState } from "react";
import ProductImageEditor from "./ImageEditor";
import EditorActions from "./EditorActions";
import PricesEditor from "./PricesEditor";
import InfosEditor from "../InfosEditor";
import { BsArrowBarDown, BsArrowBarUp } from "react-icons/bs";


const ProductEditor = ({
  product,
  priceTypes,
  deleteProduct,
  langs,
  onProductUpdated,
  uploadImage,
  providedDragHandle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onChangeInfo = (info) => {
    onProductUpdated({ ...product, info });
  };

  const onPricesUpdated = (prices) => {
    onProductUpdated({ ...product, prices });
  };

  return (
    <div className={`productEditorStyles ${isCollapsed ? "collapsed" : ""}`}>
      <div className={`drag-collapse-editor ${isCollapsed ? "collapsed" : ""}`}>
        <div className="collapsed-content">
          {isCollapsed && langs && (
            <div className="collapsed-product-names">
              <div className="product-info-div-collapsed">
                {product.info?.es?.name || "Plato sin nombre aún"}
              </div>
            </div>
          )}
        </div>
        <div className="drag-collapse ">
          <div className="drag-handle" {...providedDragHandle}>
            <div className="drag-handle-icon">⋮⋮⋮</div>
          </div>
          <div className="collapse-toggle" onClick={toggleCollapse}>
            {isCollapsed ? <BsArrowBarDown /> : <BsArrowBarUp />}
          </div>
        </div>
      </div>
      {!isCollapsed && (
        <div className="editor-styles-product">
          <ProductImageEditor
            product={product}
            onProductUpdated={onProductUpdated}
            uploadImage={uploadImage}
          />
          <div className="detailsEditorStyles">
            <div className="product-info">
              {langs && (
                <InfosEditor
                  langs={langs}
                  info={product.info}
                  productId={product.id}
                  onChange={onChangeInfo}
                />
              )}
            </div>
            {!!product.prices && (
              <PricesEditor
                priceTypes={priceTypes}
                prices={product.prices}
                onChange={onPricesUpdated}
              />
            )}
          </div>
          <EditorActions
            product={product}
            onProductUpdated={onProductUpdated}
            deleteProduct={deleteProduct}
          />
        </div>
      )}
    </div>
  );
};

export default ProductEditor;

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";

const PriceTypeEditor = ({ priceTypes, priceType, onChange, onDelete }) => {
  const [inputState, setInputState] = useState({
    value: priceType.name,
    cursorPosition: 0,
  });

  const onChangePriceType = (event) => {
    const inputValue = event.target.value;
    const newPosition = event.target.selectionStart || 0;

    setInputState({ value: inputValue, cursorPosition: newPosition });
    onChange({
      ...priceType,
      name: inputValue,
    });
  };

  return (
    <div className="price-type-editor">
      <Form.Group className="mb-3">
        <div className="d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Ej. Simple, Doble, Triple"
            value={inputState.value}
            onChange={onChangePriceType}
            selectionStart={inputState.cursorPosition}
            selectionEnd={inputState.cursorPosition}
          />
          <Button onClick={onDelete} variant="danger" className="ms-2 mb-3">
            <BiTrash />
          </Button>
        </div>
      </Form.Group>
    </div>
  );
};

export default PriceTypeEditor;
